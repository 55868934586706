import { createApi } from "@reduxjs/toolkit/query/react";
import { Button, Link, Town } from "../types/Town";
import userClient from "./axios";
import { handleError } from "./Concernings";

const ENDPOINT_BASE_URL = "https://ecoburgus.it/api/towns";
const LINK_BASE_URL = "https://ecoburgus.it/api/link";
const BUTTON_BASE_URL = "https://ecoburgus.it/api/button";

export const Towns = createApi({
  reducerPath: "api/towns",
  baseQuery: async () => {
    return { data: {} };
  },
  endpoints: (build) => {
    return {
      //Città
      createTown: build.query<
        { town: Town },
        { name: string; title: string; description: string; active: boolean }
      >({
        async queryFn(variables: {
          name: string;
          title: string;
          description: string;
          active: boolean;
        }) {
          try {
            const response = await userClient.post<{ town: Town }>(
              `${ENDPOINT_BASE_URL}/create`,
              variables
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getTowns: build.query<
        { list: Town[] },
        {
          name?: string;
          title?: string;
          description?: string;
          active?: boolean;
        }
      >({
        async queryFn(variables: {
          name?: string;
          title?: string;
          description?: string;
          active?: boolean;
        }) {
          try {
            const response = await userClient.get<{ list: Town[] }>(
              `${ENDPOINT_BASE_URL}`,
              {
                params: variables,
              }
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getTown: build.query<
        { town: Town },
        {
          id: number;
        }
      >({
        async queryFn(variables: { id: number }) {
          try {
            const response = await userClient.get<{ town: Town }>(
              `${ENDPOINT_BASE_URL}/${variables.id}`
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getTownByName: build.query<
        { town: Town },
        {
          name: string;
        }
      >({
        async queryFn(variables: { name: string }) {
          try {
            const response = await userClient.get<{ town: Town }>(
              `${ENDPOINT_BASE_URL}/get-town-by-name/${variables.name}`
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      updateTown: build.query<
        { modified: Town },
        {
          id: number;
          body: {
            name?: string;
            title?: string;
            description?: string;
            active?: boolean;
          };
        }
      >({
        async queryFn(variables: {
          id: number;
          body: {
            name?: string;
            title?: string;
            description?: string;
            active?: boolean;
          };
        }) {
          try {
            const response = await userClient.put<{ modified: Town }>(
              `${ENDPOINT_BASE_URL}/${variables.id}`,
              variables.body
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      deleteTown: build.query<
        { result: string },
        {
          id: number;
        }
      >({
        async queryFn(variables: { id: number }) {
          try {
            const response = await userClient.delete<{ result: string }>(
              `${ENDPOINT_BASE_URL}/${variables.id}`
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      //Link
      createLink: build.query<
        { link: Link },
        { label: string; url: string; townId: number; }
      >({
        async queryFn(variables: {
          label: string;
          url: string;
          townId: number;
        }) {
          try {
            const response = await userClient.post<{ link: Link }>(
              `${LINK_BASE_URL}/create`,
              variables
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getLinks: build.query<
        { list: Link[] },
        {
          townId?: number;
          label?: string;
        }
      >({
        async queryFn(variables: { townId?: number; label?: string }) {
          try {
            const response = await userClient.get<{ list: Link[] }>(
              `${LINK_BASE_URL}`,
              {
                params: variables,
              }
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      updateLink: build.query<
        { modified: Link },
        {
          id: number;
          body: {
            label?: string;
            url?: string;
          };
        }
      >({
        async queryFn(variables: {
          id: number;
          body: {
            label?: string;
            url?: string;
          };
        }) {
          try {
            const response = await userClient.put<{ modified: Link }>(
              `${LINK_BASE_URL}/${variables.id}`,
              variables.body
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      deleteLink: build.query<
        { result: string },
        {
          id: number;
        }
      >({
        async queryFn(variables: { id: number }) {
          try {
            const response = await userClient.delete<{ result: string }>(
              `${LINK_BASE_URL}/${variables.id}`
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      //Buttons
      createButton: build.query<
        { button: Button },
        FormData
      >({
        async queryFn(variables: FormData) {
          try {
            const response = await userClient.post<{ button: Button }>(
              `${BUTTON_BASE_URL}/create`,
              variables
            );
            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getButtons: build.query<
        { list: Button[] },
        {
          townId?: number;
          label?: string;
        }
      >({
        async queryFn(variables: { townId?: number; label?: string }) {
          try {
            const response = await userClient.get<{ list: Button[] }>(
              `${BUTTON_BASE_URL}`,
              {
                params: variables,
              }
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      updateButton: build.query<
        { modified: Button },
        {
          id: number;
          body: FormData;
        }
      >({
        async queryFn(variables: {
          id: number;
          body: FormData;
        }) {
          try {
            const response = await userClient.put<{ modified: Button }>(
              `${BUTTON_BASE_URL}/${variables.id}`,
              variables.body
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      deleteButton: build.query<
        { result: string },
        {
          id: number;
        }
      >({
        async queryFn(variables: { id: number }) {
          try {
            const response = await userClient.delete<{ result: string }>(
              `${BUTTON_BASE_URL}/${variables.id}`
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
    };
  },
});

export const {
  useLazyCreateTownQuery,
  useGetTownsQuery,
  useLazyGetTownsQuery,
  useLazyGetTownQuery,
  useGetTownQuery,
  useLazyDeleteTownQuery,
  useLazyUpdateTownQuery,
  useGetTownByNameQuery,
  useLazyGetTownByNameQuery,
  useLazyCreateLinkQuery,
  useLazyGetLinksQuery,
  useLazyUpdateLinkQuery,
  useLazyDeleteLinkQuery,
  useLazyCreateButtonQuery,
  useLazyGetButtonsQuery,
  useLazyDeleteButtonQuery,
  useLazyUpdateButtonQuery,
} = Towns;
