import { createApi } from "@reduxjs/toolkit/query/react";
import { Concerning } from "../types/Concernings";
import userClient from "./axios";

const ENDPOINT_BASE_URL = "https://ecoburgus.it/api/contents/concernings";
const ENDPOINT_PUBLIC_BASE_URL = "https://ecoburgus.it/api/contents";
const CONTENT_BASE_URL = "https://ecoburgus.it/api/contents/images";

export const Concernings = createApi({
  reducerPath: "api/concernings",
  baseQuery: async () => {
    return { data: {} };
  },
  endpoints: (build) => {
    return {
      createConcerning: build.query<{ concerning: Concerning }, FormData>({
        async queryFn(variables: FormData) {
          try {
            const response = await userClient.post<{ concerning: Concerning }>(
              `${ENDPOINT_BASE_URL}`,
              variables
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getConcernings: build.query<
        { list: Concerning[] },
        { title?: string; text?: string; active?: boolean }
      >({
        async queryFn(variables: {
          title?: string;
          text?: string;
          active?: boolean;
        }) {
          try {
            const response = await userClient.get<{ list: Concerning[] }>(
              `${ENDPOINT_BASE_URL}`,
              {
                params: variables,
              }
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getActiveConcerning: build.query<{ concerning: Concerning }, {}>({
        async queryFn(variables: {}) {
          try {
            const response = await userClient.get<{ concerning: Concerning }>(
              `${ENDPOINT_PUBLIC_BASE_URL}/active-concerning`,
              {
                params: variables,
              }
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getConcerning: build.query<{ concerning: Concerning }, { id: number }>({
        async queryFn(variables: { id: number }) {
          try {
            const response = await userClient.get<{ concerning: Concerning }>(
              `${ENDPOINT_BASE_URL}/${variables.id}`
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      updateConcerning: build.query<
        { modified: Concerning },
        { id: number; fd: FormData }
      >({
        async queryFn(variables: { id: number; fd: FormData }) {
          try {
            const response = await userClient.put<{ modified: Concerning }>(
              `${ENDPOINT_BASE_URL}/${variables.id}`,
              variables.fd
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      deleteConcerning: build.query<{ result: string }, { id: number }>({
        async queryFn(variables: { id: number }) {
          try {
            const response = await userClient.delete<{ result: string }>(
              `${ENDPOINT_BASE_URL}/${variables.id}`
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getImageSignedUrl: build.query<Blob, { path: string }>({
        async queryFn(variables: { path: string }) {
          try {
            const response = await userClient.post<Blob>(
              `${CONTENT_BASE_URL}`,
              variables,
              {
                responseType: "blob", // Questo è fondamentale per ricevere i file binari
              }
            );

            if (response.status === 200) {
              return { data: response.data };
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
    };
  },
});

export const handleError = () => {
  throw new Error("Errore nel reperimento dei dati richiesti");
};

export const {
  useGetConcerningsQuery,
  useLazyGetConcerningsQuery,
  useLazyGetConcerningQuery,
  useLazyGetActiveConcerningQuery,
  useLazyCreateConcerningQuery,
  useLazyUpdateConcerningQuery,
  useLazyGetImageSignedUrlQuery,
  useLazyDeleteConcerningQuery,
} = Concernings;
