import { createApi } from "@reduxjs/toolkit/query/react";
import { Button, Link, Town } from "../types/Town";
import userClient from "./axios";
import { handleError } from "./Concernings";
import { Whistleblowing } from "../types/Whistleblowing";

const ENDPOINT_BASE_URL = "https://ecoburgus.it/api/contents/whistleblowing";

export const Whistleblowings = createApi({
  reducerPath: "api/whistleblowings",
  baseQuery: async () => {
    return { data: {} };
  },
  endpoints: (build) => {
    return {
      //Città
      createWhistleblowing: build.query<
        { whistleBlowing: Whistleblowing },
        FormData
      >({
        async queryFn(variables: FormData) {
          try {
            const response = await userClient.post<{
              whistleBlowing: Whistleblowing;
            }>(`${ENDPOINT_BASE_URL}`, variables);

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getWhistleblowings: build.query<
        { list: Whistleblowing[] },
        {
          title?: string;
          text?: string;
          buttonLabel?: string;
          buttonLink?: string;
          active?: boolean;
        }
      >({
        async queryFn(variables: {
          title?: string;
          text?: string;
          buttonLabel?: string;
          buttonLink?: string;
          active?: boolean;
        }) {
          try {
            const response = await userClient.get<{ list: Whistleblowing[] }>(
              `${ENDPOINT_BASE_URL}`,
              {
                params: variables,
              }
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getWhistleblowing: build.query<
        { whistleBlowing: Whistleblowing },
        {
          id: number;
        }
      >({
        async queryFn(variables: { id: number }) {
          try {
            const response = await userClient.get<{
              whistleBlowing: Whistleblowing;
            }>(`${ENDPOINT_BASE_URL}/${variables.id}`);

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      updateWhistleblowing: build.query<
        { modified: Whistleblowing },
        {
          id: number;
          body: FormData;
        }
      >({
        async queryFn(variables: { id: number; body: FormData }) {
          try {
            const response = await userClient.put<{ modified: Whistleblowing }>(
              `${ENDPOINT_BASE_URL}/${variables.id}`,
              variables.body
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      deleteWhistleblowing: build.query<
        { result: string },
        {
          id: number;
        }
      >({
        async queryFn(variables: { id: number }) {
          try {
            const response = await userClient.delete<{ result: string }>(
              `${ENDPOINT_BASE_URL}/${variables.id}`
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
    };
  },
});

export const {
  useLazyCreateWhistleblowingQuery,
  useGetWhistleblowingsQuery,
  useLazyGetWhistleblowingsQuery,
  useLazyGetWhistleblowingQuery,
  useGetWhistleblowingQuery,
  useLazyUpdateWhistleblowingQuery,
  useLazyDeleteWhistleblowingQuery,
} = Whistleblowings;
