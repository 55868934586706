import { createApi } from "@reduxjs/toolkit/query/react";
import userClient from "./axios";
import { Certification } from "../types/Certifications";

const ENDPOINT_BASE_URL = "https://ecoburgus.it/api/contents/certifications";
const SR_ENDPOINT_BASE_URL = "https://ecoburgus.it/api/contents/social-responsibility";
const CONTENT_BASE_URL = "https://ecoburgus.it/api/contents/images";

export const Certifications = createApi({
  reducerPath: "api/certifications",
  baseQuery: async () => {
    return { data: {} };
  },
  endpoints: (build) => {
    return {
      createCertification: build.query<
        { certification: Certification },
        FormData
      >({
        async queryFn(variables: FormData) {
          try {
            const response = await userClient.post<{
              certification: Certification;
            }>(`${ENDPOINT_BASE_URL}`, variables);

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      createSocialResponsibility: build.query<
      { certification: Certification },
      FormData
    >({
      async queryFn(variables: FormData) {
        try {
          const response = await userClient.post<{
            certification: Certification;
          }>(`${SR_ENDPOINT_BASE_URL}`, variables);

          if (response.data && response.status === 200) {
            return response;
          } else {
            return handleError();
          }
        } catch (error) {
          return { error };
        }
      },
    }),
      getCertifications: build.query<
        { list: Certification[] },
        { title?: string; text?: string; active?: boolean }
      >({
        async queryFn(variables: { title?: string; text?: string }) {
          try {
            const response = await userClient.get<{ list: Certification[] }>(
              `${ENDPOINT_BASE_URL}`,
              {
                params: variables,
              }
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getCertification: build.query<
        { certification: Certification },
        { id: number }
      >({
        async queryFn(variables: { id: number }) {
          try {
            const response = await userClient.get<{
              certification: Certification;
            }>(`${ENDPOINT_BASE_URL}/${variables.id}`);

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getSocialResponsibilities: build.query
      <{list: Certification[]}, {title?: string, text?: string}>({
        async queryFn(variables: { title?: string; text?: string }) {
          try {
            const response = await userClient.get<{ list: Certification[] }>(
              `${SR_ENDPOINT_BASE_URL}`,
              {
                params: variables,
              }
            );

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      deleteCertification: build.query<{ result: string }, { id: number }>({
        async queryFn(variables: { id: number }) {
          try {
            const response = await userClient.delete<{
              result: string;
            }>(`${ENDPOINT_BASE_URL}/${variables.id}`);

            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      updateCertification: build.query<
        { modified: Certification },
        { id: number; fd: FormData }
      >({
        async queryFn(variables: { id: number; fd: FormData }) {
          try {
            const response = await userClient.put<{ modified: Certification }>(
              `${ENDPOINT_BASE_URL}/${variables.id}`,
              variables.fd
            );
            if (response.data && response.status === 200) {
              return response;
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
      getImageSignedUrl: build.query<Blob, { path: string }>({
        async queryFn(variables: { path: string }) {
          try {
            const response = await userClient.post<Blob>(
              `${CONTENT_BASE_URL}`,
              variables,
              {
                responseType: "blob", // Questo è fondamentale per ricevere i file binari
              }
            );

            if (response.status === 200) {
              return { data: response.data };
            } else {
              return handleError();
            }
          } catch (error) {
            return { error };
          }
        },
      }),
    };
  },
});

export const handleError = () => {
  throw new Error("Errore nel reperimento dei dati richiesti");
};

export const {
  useGetCertificationsQuery,
  useGetSocialResponsibilitiesQuery,
  useLazyCreateSocialResponsibilityQuery,
  useLazyGetCertificationsQuery,
  useLazyGetCertificationQuery,
  useLazyCreateCertificationQuery,
  useLazyUpdateCertificationQuery,
  useLazyGetImageSignedUrlQuery,
  useLazyDeleteCertificationQuery,
  useLazyGetSocialResponsibilitiesQuery,
} = Certifications;
